import React, {useEffect, useState} from 'react';
import '../styles/EventPreviewDark.css';
import {
    Alert,
    Button, CircularProgress, ClickAwayListener,
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField, Typography, Tooltip
} from "@mui/material";
import { Tooltip as ReactTooltip, removeStyle } from 'react-tooltip';
import {IoClose} from "react-icons/io5";
import {FaRegCalendarAlt} from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
import {BsPersonPlus, BsTrash} from "react-icons/bs";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import EventsSwiper from "../components/EventsSwiper";
import NavbarHomePage from "../components/NavbarHomePage";
import {useLocation, useNavigate} from "react-router";
import Chip from "@mui/material/Chip";
import Footer from "../components/Footer";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {number_format} from "../components/functions/numberFormat";
import {Helmet} from "react-helmet-async";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import {GiCardExchange} from "react-icons/gi";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {BiBuildingHouse} from "react-icons/bi";
import MyMap, {GoogleMap} from "../components/GoogleMapComponent";
import {styled} from "@mui/system";
import {loadPixel} from "../components/functions/useMetaPixel";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '10px',
    },
}));

const EventPreview = (props) => {

    const user = useSelector(selectUser);

    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const track = query.get('track');

    const [metaPixel, setMetaPixel] = useState(null);

    const [selectedTickets, setSelectedTickets] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [event, setEvent] = useState({id: "", name: "", description: "", date_start: "", date_end: "", location: "", limitage: "", show_related_events: 1});
    const [relatedEvents, setRelatedEvents] = useState([]);
    const [producerRelatedEvents, setProducerRelatedEvents] = useState(false);
    const [trackId, setTrackId] = useState(track);
    const [resaleLink, setResaleLink] = useState("");

    const [USDMEPRate, setUSDMEPRate] = useState(null);


    const [fp_fee, setFp_fee] = useState(0.15);

    const [values, setValues] = useState({
        buyerData: {
            name: "",
            surname: "",
            email: "",
            confirmEmail: "",
            phone: "",
            dni: "",
            province: "",
        },
        ticketsData: [{
            ticketId: "",
            ticketPrice: "",
            ticketName: "",
            ticketDNI: "",
        }],
        paymentMethod: "payway",
    })

    const [errors, setErrors] = useState({
        buyerData: {
            name: false,
            surname: false,
            email: false,
            confirmEmail: false,
            phone: false,
            dni: false,
            province: false,
        },
        ticketsData: [{
            ticketId: false,
            ticketPrice: false,
            ticketName: false,
            ticketDNI: false,
        }],
    });

    const [errorMessages, setErrorMessages] = useState({
        buyerData: {
            name: "",
            surname: "",
            email: "",
            confirmEmail: "",
            phone: "",
            dni: "",
            province: "",
        },
        ticketsData: [{
            ticketId: "",
            ticketPrice: "",
            ticketName: "",
            ticketDNI: "",
        }],
    });

    const inputValidation = (e, prop, type, index) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'name':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 2 caracteres';
                }
                break;
            case 'surname':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El apellido debe tener al menos 2 caracteres';
                }
                break;
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                if (values.buyerData.confirmEmail !== '' && value !== values.buyerData.confirmEmail) {
                    error = true;
                    errorMessage = 'Los emails no coinciden';
                }
                break;
            case 'confirmEmail':
                if (value !== values.buyerData.email) {
                    error = true;
                    errorMessage = 'Los emails no coinciden';
                }
                break;
            case 'dni':
                if (!value.match(/^[0-9]{7,8}$/)) {
                    error = true;
                    errorMessage = 'El DNI no es válido';
                }
                break;
            case 'province':
                if (value === '') {
                    error = true;
                    errorMessage = 'Debe seleccionar una provincia';
                }
                break;
            case 'ticketName':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 2 caracteres';
                }
                break;
            case 'ticketDNI':
                if (!value.match(/^[0-9]{7,8}$/)) {
                    error = true;
                    errorMessage = 'El DNI no es válido';
                }
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }

        if (type === 'buyerData') {
            setErrors({...errors, buyerData: {...errors.buyerData, [prop]: error}});
            setErrorMessages({...errorMessages, buyerData: {...errorMessages.buyerData, [prop]: errorMessage}});
        } else {
            setErrors({...errors, ticketsData: {...errors.ticketsData, [index]: {...errors.ticketsData[index], [prop]: error}}});
            setErrorMessages({...errorMessages, ticketsData: {...errorMessages.ticketsData, [index]: {...errorMessages.ticketsData[index], [prop]: errorMessage}}});
        }
    }

    const steps = [
        'Revisá tu orden',
        'Datos del comprador',
        'Datos de los tickets',
        'Confirmación',
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (e) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const submitForm = (e) => {
        e.preventDefault();
        const errorCheck = Object.values(errors).some((error) => error === true);
        if (!errorCheck) {
            handleNext();
        }
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };

    const handleBuyerChange = (prop) => (event) => {
        setValues({...values, buyerData: {...values.buyerData, [prop]:event.target.value}});
        inputValidation(event, prop, 'buyerData');
    }

    const handleTicketChange = (prop, index, ticketId, ticketPrice) => (event) => {
        setValues({...values, ticketsData: {...values.ticketsData, [index]: {...values?.ticketsData[index], [prop]:event.target.value, ticketId:ticketId, ticketPrice:ticketPrice}}});
        inputValidation(event, prop, 'ticketsData', index);
    }

    const handleSelectTickets = (ticket) => {
        //add to the array the ticket.id the times the ticket.target.value is
        let ticketss = [];

        let combo = tickets.find(tick => tick.id === parseInt(ticket.target.id)).combo;

        for (let i = 0; i < ticket.target.value*combo; i++) {
            ticketss.push(parseInt(ticket.target.id));
        }
        setSelectedTickets([...selectedTickets.filter(tick => tick !== parseInt(ticket.target.id)), ...ticketss]);
    }

    const reduceTicket = (ticket, combo) => {
        if (selectedTickets.filter(tick => tick === parseInt(ticket)).length > combo) {
            for (let i = 0; i < combo; i++) {
                for (let i = 0; i < selectedTickets.length; i++) {
                    if (selectedTickets[i] === parseInt(ticket)) {
                        selectedTickets.splice(i, 1);
                        setSelectedTickets([...selectedTickets]);
                        break;
                    }
                }
            }
        }
        else {
            setSelectedTickets(selectedTickets.filter(tick => tick !== parseInt(ticket)));
        }
    }

    const addTicket = (ticket, combo) => {
        let ticketss = [];
        for (let i = 0; i < combo; i++) {
            ticketss.push(parseInt(ticket));
        }
        setSelectedTickets([...selectedTickets, ...ticketss]);
    }

    const fetchEventData = async () => {
        //const request = await fetch(`${process.env.REACT_APP_API_URL}/event?event_id=${props.event}${props.promoter ? `&promoter_id=${props.promoter}` : ""}`, {
        const request = await fetch(`${process.env.REACT_APP_API_V2_URL}/event/${props.event}${props.promoter ? `/${props.promoter}` : ""}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        if (request.status === 404) {
            navigate('/');
            return;
        }

        const response = await request.json();
        //console.log(json3);

        if (response.success === "1") {
            setEvent(response.event);
            setTickets(response.tickets);
            setFp_fee(response.fp_fee);
            setMetaPixel(response.event.meta_pixel);
            setResaleLink(response.flashtrade_link);

            if (response.event.status === 3) {
                navigate('/');
            }
        }
    }

    const fetchProvinces = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/provinces?country=1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const response = await request.json();
        setProvinceList(response);
    }

    const fetchRelatedEvents = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/related-events?event=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
        });
        const response = await request.json();

        if (response.status === "success") {
            setRelatedEvents(response.related);
            if (response.producer_related) {
                setProducerRelatedEvents(response.producer_related);
            }
        }
    }

    const fetchBuyer = async () => {
        if (user.token) {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/buyer-data?event=${props.event}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
            });
            const response = await request.json();
            setValues({...values, buyerData: response});
        }
    }

    const fetchData = async () => {
        await Promise.all([
            fetchEventData(),
            fetchProvinces(),
            fetchBuyer(),
            fetchRelatedEvents(),
        ]);
        setIsLoaded(true);
    }

    const checkout = async () => {
        window.scrollTo(0, 0);
        setOpen(false);
        window.scrollTo(0, 0);
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/checkout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "buyerData": values.buyerData,
                "tickets": values.ticketsData,
                "promoter": event.promoter,
                "event": event.id,
                "track_id": trackId,
                "paymentMethod": values.paymentMethod,
                "event_single_link": event?.event_link,
                "total": number_format(tickets.map(ticket =>
                    (ticket.price) /
                    ticket.combo *
                    selectedTickets.filter(t => t === ticket.id).length
                ).reduce((a, b) => a + b, 0) * (1 + fp_fee), 2, '.', ''),
            })
        });

        const response = await request.text();

        if (request.status === 200) {
            window.location.href = response;
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);


    useEffect(() => {
        if (activeStep === 2) {
            setValues({...values, ticketsData: [...selectedTickets.map((ticket, index) => {
                    if (index !== 0) {
                        return {
                            ticketId: ticket,
                            ticketPrice: tickets.find(tick => tick.id === ticket).price,
                            ticketName: '',
                            ticketDNI: '',
                        }
                    } else {
                        return {
                            ticketId: ticket,
                            ticketPrice: tickets.find(tick => tick.id === ticket).price,
                            ticketName: values.buyerData.name + ' ' + values.buyerData.surname,
                            ticketDNI: values.buyerData.dni,
                        }
                    }
                })]});
            setErrors({...errors, ticketsData: [...selectedTickets.map((ticket, index) => {
                    return {
                        ticketId: false,
                        ticketPrice: false,
                        ticketName: false,
                        ticketDNI: false,
                    }
                })]});
            setErrorMessages({...errorMessages, ticketsData: [...selectedTickets.map((ticket, index) => {
                    return {
                        ticketId: '',
                        ticketPrice: '',
                        ticketName: '',
                        ticketDNI: '',
                    }
                })]});
        }
    }, [selectedTickets, activeStep]);

    useEffect(() => {
        const errorCheck = Object.values(errors.buyerData).some((error) => error === true);
        const requiredCheck = Object.values(values.buyerData).some((value) => value === '');

        if (activeStep === 1) {
            if (!errorCheck && !requiredCheck) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }

            if (values.buyerData.email === values.buyerData.confirmEmail) {
                setErrors({...errors, buyerData: {...errors.buyerData, confirmEmail: false, email: false}});
                setErrorMessages({
                    ...errorMessages,
                    buyerData: {...errorMessages.buyerData, confirmEmail: '', email: ''}
                });
            }
        }
    }, [values.buyerData, activeStep]);


    useEffect(() => {
        let errorCheck = false;
        let requiredCheck = false;

        if (activeStep === 2) {

            for (let i = 0; i < selectedTickets.length; i++) {
                try {
                    errorCheck = Object.values(errors.ticketsData[i]).some((error) => error === true);
                    requiredCheck = Object.values(values.ticketsData[i]).some((value) => value === '');

                    if (errorCheck || requiredCheck) {
                        break;
                    }
                } catch (e) {
                }
            }

            if (!errorCheck && !requiredCheck) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        }
    }, [values.ticketsData, activeStep]);

    useEffect(()=>  {
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        loadPixel(metaPixel);
        // Fire a standard event, e.g., PageView
        window.fbq('track', 'PageView');
    }, [metaPixel]);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [openedByClick, setOpenedByClick] = useState(false);

    const handleTooltipClose = () => {
        if (!openedByClick) {
            setTooltipOpen(false);
        }
    };

    const handleTooltipOpen = () => {
        setOpenedByClick(false);
        setTooltipOpen(true);
    };

    const handleClick = () => {
        setOpenedByClick(true);
        setTooltipOpen(!tooltipOpen);
    };

    const handleClickAway = () => {
        setOpenedByClick(false);
        setTooltipOpen(false);
    };

    useEffect(() => {
        removeStyle();
    }, []);

    return (
        <div className="eventPreviewDarkPage">
            <Helmet>
                <meta property="og:image" content={event?.portada} />
                <meta property="og:title" content={event?.name} />
                <meta name="title" content={event?.name} />
                <meta name="description" content={event?.description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={"https://www.flashpass.com.ar/event/" + props.event} />
                <meta property="og:description" content={event?.description} />
                <title>{event?.name ? `${event?.name} - FlashPass` : `FlashPass`}</title>
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <NavbarHomePage page={"event"} />
            <div className="p-md-5 pt-3 pb-3" style={{backgroundColor: "#1a1a1a", marginTop: "60px"}}>
                <div className="container">
                    <div className="eventCardPreviewDark p-3 p-md-4 p-lg-5" style={{minHeight: "100vh"}}>
                        {!isLoaded ? (
                            <div style={{position: "absolute", top: "50%", left: "50%", transform: `translate(-50%, -50%)`}}>
                                <CircularProgress color="secondary" size={100} />
                            </div>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div style={{overflow: "hidden", borderRadius: ".5rem"}}>
                                            <img className="img-fluid" src={event?.portada}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 ps-lg-5">
                                        <div className="row">
                                            <div className="col-12 mt-5 mt-lg-0">
                                                <h2 className="d-flex align-items-center">{event?.name}{event?.status === 0 && <Chip label="FINALIZADO" color="error" className="ms-3" />}</h2>
                                                <h5 style={{opacity: "0.75"}}>{event?.producer}</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <FaRegCalendarAlt/><span className="ms-2">Fecha</span>
                                                <Typography variant="body2" color="text"
                                                            className="mt-2 opacity-60">
                                                    {
                                                        event?.date_start
                                                    }
                                                </Typography>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <MdLocationOn />
                                                <span className="ms-2">Ubicación</span>
                                                <Typography
                                                    variant="body2"
                                                    color="text"
                                                    className="mt-2 opacity-60"
                                                    onClick={() => {
                                                        const element = document.getElementById("location-detail");
                                                        if (element) {
                                                            const rect = element.getBoundingClientRect();
                                                            const offsetTop = rect.top + window.pageYOffset;
                                                            window.scrollTo({
                                                                top: offsetTop - 70,
                                                                behavior: 'smooth'
                                                            });
                                                        }
                                                    }}
                                                    style={{cursor: "pointer", textDecoration: "underline"}}
                                                >
                                                    {event?.venue ? event?.venue : event?.location}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {event?.limitage && (
                                                <div className="col-md-6 mt-3">
                                                    <BsPersonPlus/><span className="ms-2">Edad mínima</span>
                                                    <Typography variant="body2" color="text"
                                                                className="mt-2 opacity-60">{event?.limitage} años</Typography>
                                                </div>
                                            )}
                                        </div>
                                        {event?.whitelist && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <hr className="mb-4 mt-4"/>
                                                    <Alert variant="outlined" severity="info">
                                                        El evento es exclusivo para miembros seleccionados por el Organizador. Si no eres miembro, no podrás adquirir tickets.
                                                    </Alert>
                                                </div>
                                            </div>
                                        )}
                                        {event?.status === 1 && (
                                            <>
                                                <hr className="mb-3 mt-4" style={{marginTop: 0}}/>
                                                {tickets.length > 0 ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <b>Tickets</b>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                <div className="eventTicketsDark">
                                                                    <table className="table table-borderless table-sm tableTicketsDark">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col">Ticket</th>
                                                                            <th scope="col">Precio</th>
                                                                            <th scope="col">Cantidad</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {tickets.map((ticket, index) => (
                                                                            <tr key={index}>
                                                                                <td className="tableDesc" style={{verticalAlign: "middle"}}>
                                                                                    <div>
                                                                                        {ticket.name}
                                                                                        <small className="d-block" style={{opacity: "0.75", fontSize: "60%"}}>{ticket.description}</small>
                                                                                    </div>
                                                                                </td>
                                                                                <td style={{verticalAlign: "middle", minWidth: "100px"}}>
                                                                                    {ticket.price > 0 ? (
                                                                                        ticket.currency === "USD" ? (
                                                                                            event.service_charge ? (
                                                                                                <>
                                                                                                    <div className="priceTextTicketEvent">
                                                                                                        <p className="price-line">
                                                                                                            <strong>US$ {number_format(ticket.priceUSD, 2, ',', '.')}</strong>
                                                                                                            <span className="info-icon">
                                                                                        <div>
                                                                                            <Tooltip
                                                                                                className="ms-0"
                                                                                                title="El valor expresado en dólares es solamente de referencia."
                                                                                                placement="bottom"
                                                                                            >
                                                                                                <IconButton>
                                                                                                    <InfoIcon style={{ fontSize: '12px' }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    </span>
                                                                                                        </p>
                                                                                                        <p className="price-line">$ {number_format(ticket.price, 2, ',', '.')}</p>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    $
                                                                                                    {number_format((1 + fp_fee) * ticket.price, 2, ',', '.')}
                                                                                                </>
                                                                                            )
                                                                                        ) : (
                                                                                            event.service_charge ?
                                                                                                <>
                                                                                                    $
                                                                                                    {number_format(ticket.price, 2, ',', '.')}
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    $
                                                                                                    {number_format((1 + fp_fee) * ticket.price, 2, ',', '.')}
                                                                                                </>
                                                                                        )
                                                                                    ) : (
                                                                                        "Gratuita"
                                                                                    )}
                                                                                </td>
                                                                                <td className="selectAmmount" style={{verticalAlign: "middle", minWidth: "115px"}}>
                                                                                    {ticket.available === 1 && (
                                                                                        <select id={ticket.id}
                                                                                                value={selectedTickets.filter(tickets => tickets === ticket.id).length / ticket?.combo}
                                                                                                onChange={handleSelectTickets}
                                                                                                className="form-select"
                                                                                                style={{maxWidth: "8rem", minWidth: "4rem"}}>
                                                                                            <option key={0}>0</option>
                                                                                            {(ticket.quantity / ticket.combo) < ticket.limit_per_order ? (Array.from(Array(Math.floor(ticket.quantity / ticket.combo)).keys()).map((i) => (
                                                                                                <option key={i + 1}
                                                                                                        value={i + 1}>{i + 1}</option>
                                                                                            ))) : (
                                                                                                Array.from(Array(ticket.limit_per_order).keys()).map((i) => (
                                                                                                    <option key={i + 1}
                                                                                                            value={i + 1}>{i + 1}</option>
                                                                                                ))
                                                                                            )}
                                                                                        </select>
                                                                                    )}
                                                                                    {ticket.available === 0 && ticket.show_soon === 0 && (
                                                                                        <Button className="w-100" style={{
                                                                                            maxWidth: "8rem",
                                                                                            fontSize: "0.7rem",
                                                                                            //backgroundColor: "rgba(173,2,30,0.4)"
                                                                                        }} color="error" variant="contained" disabled>
                                                                                            Agotado
                                                                                        </Button>
                                                                                    )}
                                                                                    {ticket.available === 0 && ticket.show_soon === 1 && (
                                                                                        <Button className="w-100" style={{
                                                                                            maxWidth: "8rem",
                                                                                            fontSize: "0.55rem",
                                                                                            //backgroundColor: "rgba(173,2,30,0.4)"
                                                                                        }} color="info" variant="contained" disabled>
                                                                                            Próximamente
                                                                                        </Button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {tickets.map(ticket => ticket.price / ticket.combo * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".") !== "0" && (
                                                            <div className="row">
                                                                <div className="col-12 mt-3">
                                                                    <b style={{fontSize: "1.5rem"}}>{event.service_charge ? "Subtotal" : "Total"}</b>
                                                                    <p style={{fontSize: "2rem"}}>
                                                                        <b>
                                                                            {event.service_charge ? (
                                                                                <>
                                                                                    {tickets.some(ticket => selectedTickets.includes(ticket.id) && ticket.currency === "USD") ? "AR$ " : "$ "}
                                                                                    {number_format(tickets
                                                                                            .map(ticket => ticket.price / ticket.combo * selectedTickets.filter(selTicket => selTicket === ticket.id).length)
                                                                                            .reduce((a, b) => a + b, 0)
                                                                                        , 2, ',', '.')
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {tickets.some(ticket => selectedTickets.includes(ticket.id) && ticket.currency === "USD") ? "AR$ " : "$ "}
                                                                                    {
                                                                                        number_format(
                                                                                            tickets.map(ticket => (1 + fp_fee) * ticket.price / ticket.combo * selectedTickets.filter(selTicket => selTicket === ticket.id).length)
                                                                                                .reduce((a, b) => a + b, 0), 2, ',', '.')
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className={`row align-items-center mt-0 ${tickets.map(ticket => ticket.price / ticket.combo * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".") === "0" ? "mt-3" : null}`}>
                                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                                <button className="btn btn-primary" onClick={() => setOpen(true)}
                                                                        disabled={!selectedTickets.length > 0}>{tickets.map(ticket => ticket.price / ticket.combo * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".") !== "0" ? "Comprar" : "Adquirir"}
                                                                </button>
                                                                {resaleLink && (
                                                                    <div className={"d-flex align-items-center"}>
                                                                        <a className="btn btn-primary" href={resaleLink} target={"_blank"}>
                                                                            <div className="row">
                                                                                <div className="col-12 d-flex align-items-center">
                                                                                    <GiCardExchange className={"me-2"} size="20" />
                                                                                    FlashTrade
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <div>
                                                                            <>
                                                                                <IconButton data-tip data-for="infoTip">
                                                                                    <InfoIcon />
                                                                                </IconButton>
                                                                                <ReactTooltip id="infoTip" place="bottom" effect="solid">
                                                                                    Esta es una reventa 100% segura, oficial y transparente que te garantiza adquirir tickets auténticos
                                                                                </ReactTooltip>
                                                                            </>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Alert variant="outlined" severity="info">
                                                                El evento no tiene tickets disponibles.
                                                            </Alert>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <Typography variant="h6" gutterBottom>
                                            Descripción
                                        </Typography>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12" style={{whiteSpace: "pre-wrap"}}>
                                        <p style={{color: "rgba(140,142,145,1.00)"}}>{event?.description}</p>
                                    </div>
                                </div>
                                <div className="row mt-4" id={"location-detail"}>
                                    <div className="col-12">
                                        <Typography variant="h6" gutterBottom>
                                            Lugar
                                        </Typography>
                                    </div>
                                </div>
                                {event?.venue && (
                                    <div className="row mt-2">
                                        <div className="col d-flex align-items-center">
                                    <span className="mb-0 d-flex align-items-center">
                                        <BiBuildingHouse className="me-2 icon" size={24} />
                                        {event?.venue}
                                    </span>
                                        </div>
                                    </div>
                                )}
                                <div className="row mt-2">
                                    <div className="col d-flex align-items-center">
                                <span className="mb-0 d-flex align-items-center">
                                    <HiOutlineLocationMarker className="me-2 icon" size={24} />
                                    {event?.full_location}
                                </span>
                                    </div>
                                </div>
                                {/*
                        <div className="row align-items-center">
                            <div className="col-lg-8 mt-3">
                                <div className="map-container">
                                    <MyMap address={event?.full_location} venue={event?.venue} />
                                </div>
                            </div>
                        </div>
                        */}
                                <div className="row mt-5">
                                    <div className="col-12" style={{whiteSpace: "pre-wrap",textAlign: "justify", textJustify: "inter-word"}}>
                                        <small className="text-muted" style={{fontSize: ".8rem"}}>La carga de productos y textos en el sistema licenciado son de exclusiva responsabilidad del Productor/Organizador. FlashPass no se hace responsable civil o jurídicamente por las declaraciones emitidas por estos en lo relativo a los productos vendidos en su plataforma. El Productor/Organizador es el único y exclusivo responsable de la producción y organización del Evento, en forma oportuna y en conformidad a la ley. <a href={"https://flashpass.com.ar/terminos-y-condiciones"} className="text-muted text-decoration-underline">Ver términos y condiciones</a>.</small>
                                    </div>
                                </div>
                                {relatedEvents?.length > 0 && producerRelatedEvents ? (
                                    <div className="row mt-5">
                                        <EventsSwiper target={"_blank"} events={relatedEvents} title={"Más eventos de este organizador"} theme="dark" />
                                    </div>
                                ) : relatedEvents?.length > 0 && !producerRelatedEvents && event?.show_related_events === 1 ? (
                                    <div className="row mt-5">
                                        <EventsSwiper target={"_blank"} events={relatedEvents} title={"Otros eventos que te podrían interesar"} theme="dark" />
                                    </div>
                                ) : null}
                            </>)}
                    </div>
                </div>
            </div>
            <Footer />


            <StyledDialog onClose={handleClose} open={open} fullWidth className="eventDialogContainer">
                <div className="container pt-3" style={{position: "relative"}}>
                    <div className="closeIconDark p-1 align-items-center d-flex justify-content-center">
                        <IoClose size={25} color={"rgba(255, 255, 255, 0.38)"} onClick={handleClose}/>
                    </div>
                    <div className="p-2 p-md-3 p-lg-5">
                        <div className="row">
                            <div className="col-12">
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>
                                                <div className="d-none d-md-block">{label}</div>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </div>
                        {activeStep === 0 && (
                            <>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Tus entradas</p>
                                    </div>
                                </div>
                                {tickets.map((ticket, index) => selectedTickets.filter(tickets => tickets === ticket.id).length > 0 && (
                                    <div className="row mb-2" key={index}>
                                        <div className="col-12">
                                            <div className="p-2"
                                                 style={{borderRadius: "0.5rem", border: "solid 1px"}}>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <span><b>{ticket.name}</b></span>
                                                        <p className="mt-0 mb-0">
                                                            {
                                                                number_format((ticket.price / ticket.combo * selectedTickets.filter(sel => sel === ticket.id).length), 2, ',', '.') === "0"
                                                                    ? "Gratuita"
                                                                    :
                                                                    `${tickets.some(t => selectedTickets.includes(t.id) && t.currency === "USD") ? 'AR$' : '$'} ${event.service_charge
                                                                        ? number_format(((ticket.price) / ticket.combo * selectedTickets.filter(sel => sel === ticket.id).length),2, ',', '.')
                                                                        : number_format((ticket.price * (1 + fp_fee) / ticket.combo * selectedTickets.filter(sel => sel === ticket.id).length), 2, ',', '.')
                                                                    }`
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="col-auto text-end Buttons">
                                                        {selectedTickets.filter(tickets => tickets === ticket.id).length > ticket.combo ? (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent"
                                                            }} onClick={() => reduceTicket(ticket.id, ticket.combo)}><AiOutlineMinus /></button>
                                                        ) : (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent"
                                                            }} onClick={() => reduceTicket(ticket.id, ticket.combo)}><BsTrash
                                                                style={{marginBottom: "2px", marginRight: "2px"}} size={14}/></button>
                                                        )}
                                                        <span>{selectedTickets.filter(tickets => tickets === ticket.id).length / ticket.combo}</span>
                                                        {selectedTickets.filter(tickets => tickets === ticket.id).length < ticket.quantity && selectedTickets.filter(tickets => tickets === ticket.id).length < ticket.limit_per_order ? (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                            }} onClick={() => addTicket(ticket.id, ticket.combo)}><AiOutlinePlus /></button>
                                                        ) : (
                                                            <button style={{
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                                opacity: "0.5"
                                                            }} disabled><AiOutlinePlus /></button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="row mt-3">
                                    <div className="col">
                                        <p className="mb-0">Costo de las entradas:</p>
                                        {event.service_charge ? <p style={{fontSize: ".8rem"}}>Cargos por servicio:</p> : <p></p>}
                                    </div>
                                    <div className="col-auto text-end">
                                        <p className="mb-0">
                                            {number_format(
                                                tickets.map(ticket =>
                                                    (ticket.price) /
                                                    ticket.combo *
                                                    selectedTickets.filter(t => t === ticket.id).length
                                                ).reduce((a, b) => a + b, 0) * (event.service_charge ? 1 : (1 + fp_fee)),
                                                0, ',', '.') === "0" ? "Gratuito" : `${tickets.some(t => selectedTickets.includes(t.id) && t.currency === "USD") ? 'AR$' : '$'} ${number_format(tickets.map(ticket =>
                                                (ticket.price) /
                                                ticket.combo *
                                                selectedTickets.filter(t => t === ticket.id).length
                                            ).reduce((a, b) => a + b, 0) * (event.service_charge ? 1 : (1 + fp_fee)), 2, ',', '.')}`
                                            }
                                        </p>
                                        {event.service_charge ?
                                            <p style={{fontSize: ".8rem"}}>
                                                {number_format(
                                                    tickets.map(ticket =>
                                                        fp_fee *
                                                        (ticket.price) /
                                                        ticket.combo *
                                                        selectedTickets.filter(t => t === ticket.id).length
                                                    ).reduce((a, b) => a + b, 0),
                                                    0, ',', '.') === "0" ? "Gratuito" : `${tickets.some(t => selectedTickets.includes(t.id) && t.currency === "USD") ? 'AR$' : '$'} ${number_format(tickets.map(ticket =>
                                                    fp_fee *
                                                    (ticket.price) /
                                                    ticket.combo *
                                                    selectedTickets.filter(t => t === ticket.id).length
                                                ).reduce((a, b) => a + b, 0), 2, ',', '.')}`
                                                }
                                            </p>
                                            : null}
                                    </div>
                                </div>
                                <hr className="mb-3" style={{marginTop: 0}}/>
                                <div className="row mt-3">
                                    <div className="col">
                                        <p><b>Total:</b></p>
                                    </div>
                                    <div className="col-auto text-end">
                                        <p>
                                            <b>{number_format(tickets.map(ticket =>
                                                (ticket.price) /
                                                ticket.combo *
                                                selectedTickets.filter(t => t === ticket.id).length
                                            ).reduce((a, b) => a + b, 0) * (1 + fp_fee),
                                                2, ',', '.') === "0" ? "Gratuito" : `${tickets.some(t => selectedTickets.includes(t.id) && t.currency === "USD") ? 'AR$' : '$'} ${number_format(tickets.map(ticket =>
                                                (ticket.price) /
                                                ticket.combo *
                                                selectedTickets.filter(t => t === ticket.id).length
                                            ).reduce((a, b) => a + b, 0) * (1 + fp_fee), 2, ',', '.')}`}</b>
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <Button onClick={handleNext} disabled={!selectedTickets.length > 0}>
                                            {activeStep === steps.length - 1 ? 'Ir al pago' : 'Siguiente'}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <div className="row mt-3 text-center d-md-none">
                                    <div className="col-12">
                                        <p>Datos del comprador</p>
                                    </div>
                                </div>
                                <form onSubmit={submitForm}>
                                    <div className="row text-center">
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Nombre" variant="outlined" fullWidth
                                                       value={values.buyerData.name}
                                                       onChange={handleBuyerChange('name')}
                                                       error={errors.buyerData.name}
                                                       helperText={errorMessages.buyerData.name} required/>
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <TextField label={"Apellido"} variant="outlined" fullWidth
                                                       value={values.buyerData.surname}
                                                       onChange={handleBuyerChange('surname')}
                                                       error={errors.buyerData.surname}
                                                       helperText={errorMessages.buyerData.surname} required/>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-12 mt-3">
                                            <TextField label="DNI" variant="outlined" type="number" onWheel={(e) => e.preventDefault()}  fullWidth
                                                       value={values.buyerData.dni}
                                                       onChange={handleBuyerChange('dni')}
                                                       error={errors.buyerData.dni}
                                                       helperText={errorMessages.buyerData.dni} required/>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Email" variant="outlined" type="email" fullWidth
                                                       value={values.buyerData.email}
                                                       onChange={handleBuyerChange('email')}
                                                       error={errors.buyerData.email}
                                                       helperText={errorMessages.buyerData.email} required/>
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Confirmar Email" variant="outlined" type="email"
                                                       fullWidth value={values.buyerData.confirmEmail}
                                                       onChange={handleBuyerChange('confirmEmail')}
                                                       error={errors.buyerData.confirmEmail}
                                                       helperText={errorMessages.buyerData.confirmEmail} required/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mt-3">
                                            <TextField label="Teléfono" variant="outlined" type="number" onWheel={(e) => e.preventDefault()}  fullWidth
                                                       value={values.buyerData.phone}
                                                       onChange={handleBuyerChange('phone')} required/>
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <FormControl fullWidth className="mb-3">
                                                <InputLabel id="demo-simple-select-label">Provincia de residencia</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={values.buyerData.province}
                                                    label="Provincia de residencia"
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            buyerData: {
                                                                ...values.buyerData,
                                                                province: event.target.value
                                                            }
                                                        });
                                                    }}
                                                    required
                                                >
                                                    {provinceList.map((province, key) => (
                                                        <MenuItem key={key}
                                                                  value={province.province_id}>{province.province_name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-12 mt-3">
                                            <Button onClick={handleBack}>
                                                Atrás
                                            </Button>
                                            <Button type="submit" disabled={disabledButton}>
                                                Siguiente
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                        {activeStep === 2 && (
                            <>
                                <div className="row mt-3 text-center d-md-none">
                                    <div className="col-12">
                                        <p>Datos de los tickets</p>
                                    </div>
                                </div>
                                {selectedTickets.sort().map((sel, index) => (
                                    <div key={index}>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <span>Entrada {index + 1} - {tickets?.filter(ticket => ticket?.id === sel)[0]?.name}</span>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-lg-6 mt-2">
                                                <TextField label="Nombre Completo" variant="outlined" fullWidth
                                                           value={values?.ticketsData[index]?.ticketName}
                                                           onChange={handleTicketChange('ticketName', index, tickets?.filter(ticket => ticket?.id === sel)[0]?.id, tickets?.filter(ticket => ticket?.id === sel)[0]?.price)}
                                                           error={errors?.ticketsData[index]?.ticketName}
                                                           helperText={errorMessages?.ticketsData[index]?.ticketName}/>
                                            </div>
                                            <div className="col-lg-6 mt-2">
                                                <TextField label="DNI" variant="outlined" type="number" onWheel={(e) => e.preventDefault()}  fullWidth
                                                           value={values?.ticketsData[index]?.ticketDNI}
                                                           onChange={handleTicketChange('ticketDNI', index, tickets?.filter(ticket => ticket?.id === sel)[0]?.id, tickets?.filter(ticket => ticket?.id === sel)[0]?.price)}
                                                           error={errors?.ticketsData[index]?.ticketDNI}
                                                           helperText={errorMessages?.ticketsData[index]?.ticketDNI}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="row text-center">
                                    <div className="col-12 mt-3">
                                        <Button onClick={handleBack}>
                                            Atrás
                                        </Button>
                                        <Button onClick={handleNext} disabled={disabledButton}>
                                            Siguiente
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 3 && (
                            <>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Revisa tus datos</p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Nombre: <b>{values.buyerData.name}</b></p>
                                        <p>Apellido: <b>{values.buyerData.surname}</b></p>
                                        <p>DNI: <b>{values.buyerData.dni}</b></p>
                                        <p>Email: <b>{values.buyerData.email}</b></p>
                                        <p>Teléfono: <b>{values.buyerData.phone}</b></p>
                                        <p>Tus entradas:</p>
                                        {tickets.map((ticket, index) => selectedTickets.filter(tickets => tickets === ticket.id).length > 0 && (
                                            <p key={index}>{ticket.name}: <b>{selectedTickets.filter(tickets => tickets === ticket.id).length / ticket.combo}</b>
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                {/*tickets.map(ticket => ticket.price / ticket.combo * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".") !== "0" ?
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <p className="select-payment-title">Selecciona tu método de pago:</p>
                                        <div className="payment-methods">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className="payment-method-opt" style={{border: `${values.paymentMethod === "payway" ? "5px solid #BA55D3" : "none"}`}}>
                                                        <input type="radio" name="payment-method" value="payway" checked={values.paymentMethod === "payway"} onChange={(e) => setValues({ ...values, paymentMethod: e.target.value })} />
                                                        <img className="img-fluid" src="/assets/images/Payway-Payment.png" alt="Payway Payment" />
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <label className="payment-method-opt" style={{border: `${values.paymentMethod === "mercadopago" ? "5px solid #BA55D3" : "none"}`}}>
                                                        <input type="radio" name="payment-method" value="mercadopago" checked={values.paymentMethod === "mercadopago"} onChange={(e) => setValues({ ...values, paymentMethod: e.target.value })} />
                                                        <img className="img-fluid" src="/assets/images/Mercado-Pago-Payment.png" alt="Mercado Pago Payment" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null*/}
                                <div className="row text-center mt-2">
                                    <div className="col-12">
                                        <p>Al confirmar la orden estas aceptando nuestros <a href={"https://flashpass.com.ar/terminos-y-condiciones"} style={{color: "whitesmoke"}} target={"_blank"}
                                                                                             className="text-decoration-underline">términos y condiciones</a></p>
                                    </div>
                                </div>
                                <div className="row mt-2 text-center">
                                    <div className="col-12">
                                        <Button onClick={handleBack}>
                                            Atras
                                        </Button>
                                        <Button onClick={checkout}>
                                            Confirmar
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </StyledDialog>
        </div>
    );
};

export default EventPreview;