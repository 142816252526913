import './App.css';
import { BrowserRouter } from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/configureStore";
import {ThemeProvider, createTheme} from "@mui/material";
import AnimatedRoutes from "./routes/AnimatedRoutes";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useEffect} from "react";


function App() {

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        }
    });

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        }
    });

    useEffect(() => {
        document.addEventListener('wheel', function(event) {
            if (document.activeElement.type === 'number') {
                event.preventDefault();
            }
        }, { passive: false });
    }, []);

  return (
    <div style={{overflowX: "hidden"}}>
        <ThemeProvider theme={lightTheme}>
            <Provider store={store}>
                <BrowserRouter>
                    <HelmetProvider>
                        <Helmet>
                            <title>FlashPass</title>
                            <meta name="description" content="FlashPass es una plataforma digital de venta de entradas con el objetivo de impulsar experiencias inolvidables. Permitimos a cualquier persona crear, difundir y potenciar sus eventos." />
                        </Helmet>
                        <AnimatedRoutes />
                    </HelmetProvider>
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </div>
  );
}

export default App;
