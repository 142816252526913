import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./userSlice";
import carroReducer from "./carroSlice";
import ticketsReducer from "./ticketsSlice";
import eventChangeReducer from "./eventsChangeSlice";
import menuReducer from "./menuSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        carro: carroReducer,
        tickets: ticketsReducer,
        eventChange: eventChangeReducer,
        menu: menuReducer,
    },
    devTools: false
})